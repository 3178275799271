import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Address, Label, ProfileDataType, ProfileField, SocialType } from '../types/Profile';
import {
  BusinessInvite,
  BusinessInvitePropertyType,
  CustomField,
  CustomFieldResponses,
  CustomFieldType,
} from '../services/model/inviteService.model';
import { filterCustomLabelFields } from './filterUtils';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';
import { BusinessType } from '../types/business';
import { ExcelColumn, ExcelColumnType } from '../types/misc';
import { LocalContactInfo } from '../services/model/localContactService.model';
import countries from '../locales/i18n-country';
import { sortProfileFields } from './sortUtils';

export const formatFields = (
  fields: any,
): {
  name: string;
  communicationName: string;
  email: string;
  phoneNumber: string;
  address: string;
  birthDate: string;
  custom: object;
} => {
  const {
    FIRST_NAME,
    LAST_NAME,
    COMMUNICATION_NAME,
    EMAIL,
    ADDRESS,
    BIRTHDATE,
    PHONENUMBER,
    ...custom
  } = fields;
  return {
    name: `${FIRST_NAME} ${LAST_NAME}`,
    email: EMAIL,
    communicationName: COMMUNICATION_NAME,
    phoneNumber: PHONENUMBER,
    address: ADDRESS,
    birthDate: BIRTHDATE,
    custom,
  };
};

export const formatAddress = (address: string): JSX.Element[] => {
  if (!address) return [];
  const addressArray = address.split('\n');
  return addressArray.map((line, index) => <p key={index}>{line}</p>);
};

/**
 * Creates a new empty field of the given type
 * @param dataType the type of the field to create
 * @returns a new empty field of the given type
 */
export const createEmptyField = (dataType: BusinessInvitePropertyType): ProfileField => {
  if (dataType in SocialType) {
    return {
      social: { socialType: dataType as SocialType, link: '' },
      dataType: ProfileDataType.SOCIAL,
      label: Label.GENERAL,
      description: '',
    };
  }
  const base = {
    dataType: dataType as ProfileDataType,
    label: Label.GENERAL,
    description: '',
  };
  switch (dataType) {
    case ProfileDataType.EMAIL:
      return { ...base, email: '' };
    case ProfileDataType.ADDRESS:
      return {
        ...base,
        address: {
          street: '',
          houseNumber: '',
          houseNumberAddition: '',
          city: '',
          country: '',
          postCode: '',
          countryCode: '',
        },
      };
    case ProfileDataType.PHONENUMBER:
      return {
        ...base,
        phoneNumber: {
          prefix: '',
          suffix: '',
          countryCode: '',
        },
      };
    case ProfileDataType.BIRTHDATE:
      return {
        ...base,
        birthDate: '',
      };
    case ProfileDataType.BUSINESSNAME:
      return base;
    default:
      return base;
  }
};

export const createEmptyBusinessInvite = (): BusinessInvite => {
  const business = getSelectedBusiness();
  return {
    message: business?.inviteId ? '' : getInitialInviteMessage(business?.type!),
    mandatoryFields: [],
    customFields: [],
    optionalFields: [],
    acceptAutomatically: true,
    nonRequestedFields: Object.values(BusinessInvitePropertyType),
  };
};

const getInitialInviteMessage = (type: BusinessType): string => {
  switch (type) {
    case BusinessType.WEDDING:
      return 'Wij gaan binnenkort trouwen 🥳 en misschien krijg jij wel een kaart ✉️😛';
    case BusinessType.BIRTH:
      return 'Wij krijgen binnenkort een kindje 👶 en misschien krijg jij wel een kaart ✉️😛';
    case BusinessType.FUNERAL:
      return 'Kunt u uw adres delen in verband met overlijden?';
    case BusinessType.BUSINESS:
      return 'Wij willen graag een zakelijke relatie met u aangaan. Kunt u uw adres delen?';
    case BusinessType.OTHER_MOMENT:
      return 'He, ik wil graag contact met je kunnen opnemen. Kun je jouw gegevens delen?';
    default:
      return '';
  }
};

export const createEmptyLocalContact = () => ({
  firstName: '',
  lastName: '',
  note: '',
  receivedData: [],
  customFieldResponses: createEmptyCustomFieldResponses([]),
});

export const createEmptyCustomFieldResponses = (
  customFields: CustomField[],
): CustomFieldResponses => {
  const labelFields = filterCustomLabelFields(customFields);
  const cnf = customFields.find((field) => field.type === CustomFieldType.COMMUNICATION_NAME);
  return {
    ...(cnf ? { [cnf.customFieldId!]: '' } : {}),
    ...labelFields
      .map((field) => ({
        [field.customFieldId!]: '',
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  };
};

export const formatCustomFieldResponsesFromCustomFields = (
  fields: CustomField[],
  response: CustomFieldResponses,
): CustomFieldResponses => {
  const labelFields = filterCustomLabelFields(fields);
  const cnf = fields.find((field) => field.type === CustomFieldType.COMMUNICATION_NAME);
  return {
    ...(cnf ? { [cnf.customFieldId!]: response[cnf.customFieldId!] } : {}),
    ...(labelFields
      .map((field) => {
        if (field.type === CustomFieldType.MULTIPLE_CHOICE) {
          const responseOptions = response[field.customFieldId!];
          if (!field.options.includes(responseOptions))
            return {
              [field.customFieldId!]: '',
            };
        }

        return {
          [field.customFieldId!]: response[field.customFieldId!] || '',
        };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) || {}),
  };
};

export const formatLocalContactInfosfromColumns = (
  columns: ExcelColumn[],
  types: { [key: number]: ExcelColumnType | string },
  customFields: CustomField[],
): LocalContactInfo[] => {
  const fullNameToFirstAndLastName = (fullName: string): { firstName: string; lastName: string } => {
    const splitName = fullName.split(' ');

    return {
      firstName: splitName[0].trim(),
      lastName: splitName.slice(1).join(' ').trim(),
    };
  }

  const addressStringToStreetHouseNumberAndAddition = (
    address: string,
  ): { street: string; houseNumber: string; houseNumberAddition: string } => {
    const regex = /^(\d*[\wäöüß\d '\-.]+)[,\s]+(\d+)\s*([\wäöüß\d\-/]*)$/i;
    let match = address.match(regex);
    if (!match) match = ['', address, '', ''];
    match.shift();

    return {
      street: match[0],
      houseNumber: match[1],
      houseNumberAddition: match[2],
    };
  };

  const addAddress = (info: LocalContactInfo, address: Partial<Address>): void => {
    const addressField =
      info.receivedData?.find((f) => f.dataType === ProfileDataType.ADDRESS) ||
      createEmptyField(ProfileDataType.ADDRESS);
    info.receivedData = info.receivedData?.filter((f) => f.dataType !== ProfileDataType.ADDRESS);
    info.receivedData?.push({
      ...addressField,
      address: {
        ...addressField?.address!,
        ...address,
      },
    });
  };

  const addBirthDate = (
    info: LocalContactInfo,
    birthDate: Partial<{ day: string; month: string; year: string }>,
  ): void => {
    const birthDateField =
      info.receivedData?.find((f) => f.dataType === ProfileDataType.BIRTHDATE) ||
      createEmptyField(ProfileDataType.BIRTHDATE);
    info.receivedData = info.receivedData?.filter((f) => f.dataType !== ProfileDataType.BIRTHDATE);
    const [year, month, day] = birthDateField?.birthDate?.split('-') || [];
    info.receivedData?.push({
      ...birthDateField,
      birthDate: `${birthDate.year || year || ''}-${
        birthDate.month?.padStart(2, '0') || month || ''
      }-${birthDate.day?.padStart(2, '0') || day || ''}`,
    });
  };

  const addPhoneNumber = (info: LocalContactInfo, phoneNumber: string): void => {
    //check if first two characters are 06
    if (phoneNumber.startsWith('06')) phoneNumber = phoneNumber.replace('06', '+316');
    if (phoneNumber.startsWith('6') && parsePhoneNumber(`+31${phoneNumber}`)?.isValid)
      phoneNumber = `+31${phoneNumber}`;
    if (phoneNumber && !phoneNumber.includes('+')) phoneNumber = `+${phoneNumber}`;

    const {
      countryCallingCode,
      isValid,
      country: countryCode,
      nationalNumber,
    } = parsePhoneNumber(`${phoneNumber}`) ?? {};

    if (!countryCallingCode || !isValid || !countryCode || !nationalNumber) {
      info.receivedData.push({
        ...createEmptyField(ProfileDataType.PHONENUMBER),
        phoneNumber: { prefix: '', suffix: phoneNumber, countryCode: '' },
      });
      return;
    }
    const prefix = `+${countryCallingCode}`;
    const suffix = nationalNumber;
    info.receivedData.push({
      ...createEmptyField(ProfileDataType.PHONENUMBER),
      phoneNumber: { prefix, suffix, countryCode },
    });
  };

  if (!columns.length) return [];
  const infos: LocalContactInfo[] = columns[0].rows.map(() => ({
    firstName: '',
    lastName: '',
    note: '',
    receivedData: [],
    customFieldResponses: createEmptyCustomFieldResponses([]),
  }));

  columns.forEach((column, i) => {
    column.rows.forEach((row, j) => {
      switch (types[i]) {
        case ExcelColumnType.FULL_NAME:
          const { firstName, lastName } = fullNameToFirstAndLastName(row);
          infos[j].firstName = firstName;
          infos[j].lastName = lastName;
          break;
        case ExcelColumnType.FIRST_NAME:
          infos[j].firstName = row;
          break;
        case ExcelColumnType.INFIX:
          infos[j].lastName = `${row.trim()} ${infos[j].lastName ?? ''}`.trim();
          break;
        case ExcelColumnType.LAST_NAME:
          infos[j].lastName = `${infos[j].lastName ?? ''} ${row.trim()}`.trim();
          break;
        case ExcelColumnType.EMAIL:
          infos[j].receivedData?.push({
            ...createEmptyField(ProfileDataType.EMAIL),
            email: row.trim().toLowerCase(),
          });
          break;
        case ExcelColumnType.PHONENUMBER:
          addPhoneNumber(infos[j], row);
          break;
        case ExcelColumnType.STREET:
          const address = addressStringToStreetHouseNumberAndAddition(row);
          addAddress(infos[j], address);
          break;
        case ExcelColumnType.CITY:
          addAddress(infos[j], { city: row });
          break;
        case ExcelColumnType.COUNTRY:
          const countryCode = countries.recognizeCountryCode(row);
          addAddress(infos[j], { country: row, countryCode });
          break;
        case ExcelColumnType.POSTAL_CODE:
          addAddress(infos[j], { postCode: row });
          break;
        case ExcelColumnType.BIRTHDATE:
          if (row) {
            const [day, month, year] = row.split(/-|\//).map((s) => s?.padStart(2, '0') || '');
            addBirthDate(infos[j], { day, month, year });
          }
          break;
        case ExcelColumnType.COMMUNICATION_NAME:
          const cnf = customFields.find(
            (f) => f.type === CustomFieldType.COMMUNICATION_NAME,
          )?.customFieldId;
          if (!cnf) break;
          infos[j].customFieldResponses[cnf] = row;
          break;
        default:
          const fieldId = filterCustomLabelFields(customFields).find(
            (f) => f.label === types[i],
          )?.customFieldId;
          if (!fieldId) break;
          infos[j].customFieldResponses[fieldId] = row;
          break;
      }
    });
  });

  return infos.map((info) => ({ ...info, receivedData: sortProfileFields(info.receivedData) }));
};

export const fromStringtoExcelColumnType = (type: string): ExcelColumnType | undefined => {
  const normalizedType = type.toLowerCase().trim();

  if (normalizedType.includes('first') || normalizedType.includes('voor'))
    return ExcelColumnType.FIRST_NAME;
  if (normalizedType.includes('last') || normalizedType.includes('achter'))
    return ExcelColumnType.LAST_NAME;
  if (normalizedType === 'name' || normalizedType === 'naam' || normalizedType.includes('full') || normalizedType.includes('volledig'))
    return ExcelColumnType.FULL_NAME;
  if (normalizedType.includes('mail'))
    return ExcelColumnType.EMAIL;
  if (normalizedType.includes('phone') || normalizedType.includes('telefoon'))
    return ExcelColumnType.PHONENUMBER;
  if (normalizedType.includes('communication') || normalizedType.includes('adressering'))
    return ExcelColumnType.COMMUNICATION_NAME;
  if (normalizedType.includes('street') || normalizedType.includes('straat'))
    return ExcelColumnType.STREET;
  if (normalizedType.includes('city') || normalizedType.includes('woonplaats'))
    return ExcelColumnType.CITY;
  if (normalizedType.includes('country') || normalizedType.includes('land'))
    return ExcelColumnType.COUNTRY;
  if (normalizedType.includes('zip') || normalizedType.includes('post'))
    return ExcelColumnType.POSTAL_CODE;
  if (normalizedType.includes('birth') || normalizedType.includes('jaar'))
    return ExcelColumnType.BIRTHDATE;
  if (normalizedType.includes('infix') || normalizedType.includes('tussenvoegsel'))
    return ExcelColumnType.INFIX;

  return undefined;
};

export const fromInviteToExcelColumnTypes = (invite: BusinessInvite): ExcelColumnType[] => {
  const types: ExcelColumnType[] = [
    ExcelColumnType.FIRST_NAME,
    ExcelColumnType.INFIX,
    ExcelColumnType.LAST_NAME,
    ExcelColumnType.FULL_NAME,
  ];

  const requestedData = invite.mandatoryFields.concat(invite.optionalFields);

  if (invite.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME))
    types.push(ExcelColumnType.COMMUNICATION_NAME);
  if (requestedData.includes(ProfileDataType.EMAIL)) types.push(ExcelColumnType.EMAIL);
  if (requestedData.includes(ProfileDataType.PHONENUMBER)) types.push(ExcelColumnType.PHONENUMBER);
  if (requestedData.includes(ProfileDataType.ADDRESS)) {
    types.push(ExcelColumnType.STREET);
    types.push(ExcelColumnType.POSTAL_CODE);
    types.push(ExcelColumnType.CITY);
    types.push(ExcelColumnType.COUNTRY);
  }
  if (requestedData.includes(ProfileDataType.BIRTHDATE)) types.push(ExcelColumnType.BIRTHDATE);

  return types;
};

export const resizeImage = (file: File, maxSize: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = event.target!.result as string;

      img.onload = () => {
        const { width, height } = img;

        let newWidth;
        let newHeight;

        if (width > height) {
          newWidth = maxSize;
          newHeight = (maxSize * height) / width;
        } else {
          newHeight = maxSize;
          newWidth = (maxSize * width) / height;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Failed to create blob.'));
              return;
            }

            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          },
          file.type,
          0.5,
        );
      };

      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file.'));
    };
  });
};
