import * as XLSX from 'xlsx';
import _ from 'lodash';
import { Connection } from '../redux/slices/connectionsSlice';
import { BusinessInvite, CustomFieldType } from '../services/model/inviteService.model';
import { ExcelColumn, ExcelColumnType } from '../types/misc';
import { excelColumnTypeToString, translateEventParticipationAnswer } from './stringUtils';
import { filterCustomLabelFields } from './filterUtils';
import { ProfileDataType } from '../types/Profile';
import { BasicInfo } from '../types/BasicInfo';
import { CustomEventMulipleChoiceField, EventParticipant, EventParticipationAnswer } from '../types/event';

export const downloadExcel = (data: { [key: string]: string }[], fileName: string): void => {
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

  // Set the column widths
  const columnWidths: number[] = getColumnWidths(data);
  columnWidths.forEach((width: number, colIndex: number): void => {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'][colIndex] = { width: width + 1 }; // Add some padding to the width
  });

  XLSX.utils.book_append_sheet(wb, ws, 'Connections');
  XLSX.writeFile(wb, fileName);
};

function getColumnWidths(record: Record<string, any>[]): number[] {
  const widths: number[] = [];
  record.forEach((row: Record<string, any>): void => {
    Object.keys(row).forEach((key: string, colIndex: number): void => {
      const value: any = row[key];
      const cellWidth: number = Math.max(String(key).length, String(value).length);
      widths[colIndex] = widths[colIndex] ? Math.max(widths[colIndex], cellWidth) : cellWidth;
    });
  });
  return widths;
}

export const downloadConnectionsExcel = (
  connections: Connection[],
  name?: string,
  labelsToDownload?: string[],
): void => {
  const data = connections.map((connection): { [key: string]: string } => {
    const { ADDRESS: address, ...restFields } = connection.fields;
    let usableFields = { ...restFields };
    let finalLabelsToDownload = labelsToDownload ? [...labelsToDownload] : undefined;

    if (address) {
      const [STREET, postCodeCity, COUNTRY] = address.split('\n');
      const [POSTAL_CODE, ...cityTokens] = postCodeCity.split(' ');
      usableFields = { ...usableFields, STREET, POSTAL_CODE, CITY: cityTokens.join(' '), COUNTRY };
      if (finalLabelsToDownload) {
        finalLabelsToDownload = [...finalLabelsToDownload, 'STREET', 'POSTAL_CODE', 'CITY', 'COUNTRY'];
      }
    }

    if (finalLabelsToDownload) {
      return _.pickBy(usableFields, (_value, key) =>
        key === BasicInfo.FIRST_NAME || key === BasicInfo.LAST_NAME || (finalLabelsToDownload && finalLabelsToDownload.includes(key))
      );
    }

    return usableFields;
  });

  const newData = data.map((row: { [key: string]: string }): { [key: string]: string } => {
    const newRow: { [key: string]: string } = {};
    Object.keys(row).forEach((key: string): void => {
      newRow[excelColumnTypeToString(key as ExcelColumnType)] = row[key];
    });
    return newRow;
  });
  name = name ? name.replaceAll(' ', '_') : 'vera_moment';
  downloadExcel(newData, `${name}_contacten.xlsx`);
};

export const downloadParticipationExcel = (
  connections: [Connection, EventParticipant][],
  eventFields: CustomEventMulipleChoiceField[],
  name?: string,
  labelsToDownload?: string[],
): void => {
  const allowedKeys = ['EVENT_PARTICIPATION', BasicInfo.FIRST_NAME, BasicInfo.LAST_NAME, ...eventFields.map(f => f.label)];

  const data = connections.map(([connection, participantData]): { [key: string]: string } => {
    const { ADDRESS: address, ...restFields } = connection.fields;
    let usableFields: {[p: string]: string} = {
      ...restFields,
      EVENT_PARTICIPATION: translateEventParticipationAnswer(participantData.answer ?? EventParticipationAnswer.PENDING),
      COMMENT: participantData.description,
      ...eventFields.reduce((prev, curr) => {
        prev[curr.label] = curr.responses.find(r => r.userId === participantData.id)?.response ?? '';
        return prev;
      }, {} as any),
    };

    let finalLabelsToDownload = labelsToDownload ? [...labelsToDownload] : undefined;

    if (address) {
      const [STREET, postCodeCity, COUNTRY] = address.split('\n');
      const [POSTAL_CODE, ...cityTokens] = postCodeCity.split(' ');
      usableFields = { ...usableFields, STREET, POSTAL_CODE, CITY: cityTokens.join(' '), COUNTRY };
      if (finalLabelsToDownload) {
        finalLabelsToDownload = [...finalLabelsToDownload, 'STREET', 'POSTAL_CODE', 'CITY', 'COUNTRY'];
      }
    }

    if (finalLabelsToDownload) {
      return _.pickBy(usableFields, (_value, key) =>
        allowedKeys.includes(key) || (finalLabelsToDownload && finalLabelsToDownload.includes(key))
      );
    }

    return usableFields;
  });

  const newData = data.map((row: { [key: string]: string }): { [key: string]: string } => {
    const newRow: { [key: string]: string } = {};
    const constantKeys = [ExcelColumnType.FIRST_NAME, ExcelColumnType.LAST_NAME, ExcelColumnType.EVENT_PARTICIPATION];
    const otherKeys = Object.keys(row).filter((key) => !constantKeys.includes(key as ExcelColumnType));

    [...constantKeys, ...otherKeys].forEach((key: string): void => {
      newRow[excelColumnTypeToString(key as ExcelColumnType)] = row[key];
    });

    return newRow;
  });

  name = name ? name.replaceAll(' ', '_') : 'event_list';
  downloadExcel(newData, `${name}.xlsx`);
}

export const downloadExcelExample = (invite: BusinessInvite): void => {
  const fields = [...invite.mandatoryFields, ...invite.optionalFields];

  const toString = excelColumnTypeToString;
  const headers = [toString(ExcelColumnType.FIRST_NAME), toString(ExcelColumnType.LAST_NAME)]; // Array for headers

  // Add data types and headers based on field types
  if (invite.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME)) {
    headers.push(toString(ExcelColumnType.COMMUNICATION_NAME));
  }
  fields.forEach((field) => {
    if (field === ProfileDataType.EMAIL) {
      headers.push(toString(ExcelColumnType.EMAIL));
    } else if (field === ProfileDataType.PHONENUMBER) {
      headers.push(toString(ExcelColumnType.PHONENUMBER));
    } else if (field === ProfileDataType.ADDRESS) {
      headers.push(
        toString(ExcelColumnType.STREET),
        toString(ExcelColumnType.POSTAL_CODE),
        toString(ExcelColumnType.CITY),
        toString(ExcelColumnType.COUNTRY),
      );
    } else if (field === ProfileDataType.BIRTHDATE) {
      headers.push(toString(ExcelColumnType.BIRTHDATE));
    }
  });
  if (invite.customFields.length > 0) {
    headers.push(...filterCustomLabelFields(invite.customFields).map((field) => field.label));
  }
  const excelData = [headers];

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(excelData);

  const columnWidths: number[] = getColumnWidths(excelData);
  columnWidths.forEach((width: number, colIndex: number): void => {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'][colIndex] = { width: width + 1 }; // Add some padding to the width
  });

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'output.xlsx');
};

export function parseColumns(file: File): Promise<ExcelColumn[]> {
  const processData = (data: ArrayBuffer): ExcelColumn[] => {
    const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd-mm-yy' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const serializedData: { [key: string]: string }[] = XLSX.utils.sheet_to_json(worksheet, {
      raw: false,
      defval: '',
    });
    if (!serializedData.length) throw new Error('Geen data gevonden in het bestand');
    const columns: ExcelColumn[] = [];
    Object.keys(serializedData[0]).forEach((key) => {
      columns.push({ name: key, rows: serializedData.map((row) => row[key] || '') });
    });
    return columns;
  };

  return new Promise<ExcelColumn[]>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file selected'));
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = e.target!.result as ArrayBuffer;
        const columns = processData(data);
        resolve(columns);
      } catch (error) {
        console.error('An error occurred while processing the Excel file:', error);
        reject(error);
      }
    };

    reader.readAsBinaryString(file);
  });
}
