import validator from 'email-validator';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { ProfileDataType, ProfileField } from '../types/Profile';
import { AdminRole, BusinessType } from '../types/business';
import i18n from '../locales/i18n';
import { ExcelColumnType } from '../types/misc';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import {
  BusinessInvitePropertyType,
  CustomField,
  CustomFieldType,
} from '../services/model/inviteService.model';
import { EventParticipationAnswer } from '../types/event';

/*
 * Function for converting camelCase to space separated words
 * @param {string} str - string to convert
 * @returns {string} - converted string
 */
export const camelToSpace = (str: string): string =>
  str.replace(/([A-Z])/g, (match): string => ` ${match}`).toLowerCase();

/**
 * Convert profile field to string
 * @param field profile field to convert
 * @returns string representation of profile field
 */
export function profileFieldToString(field: ProfileField): string {
  switch (field.dataType) {
    case ProfileDataType.EMAIL:
      return field.email!;

    case ProfileDataType.BUSINESSNAME:
      return field.description!;

    case ProfileDataType.PHONENUMBER:
      return `${field.phoneNumber?.prefix!} ${field.phoneNumber?.suffix!}`;

    case ProfileDataType.BIRTHDATE:
      if (!field.birthDate) return '';
      const [year, month, day] = field.birthDate.split('-');
      return `${day}-${month}-${year}`;

    case ProfileDataType.ADDRESS:
      const { address } = field;
      if (!address) return '';

      return `${address.street} ${address.houseNumber}${address.houseNumberAddition ?? ''}\n${
        address.postCode
      } ${address.city}\n${address.country}`;

    default:
      return '';
  }
}

/**
 * Convert ProfileDataType to string
 * @param type ProfileDataType to convert
 * @returns string representation of ProfileDataType
 */
export const profileDataTypeToString = (type: ProfileDataType): string => {
  switch (type) {
    case ProfileDataType.EMAIL:
      return i18n.t('dataType.email');
    case ProfileDataType.ADDRESS:
      return i18n.t('dataType.address');
    case ProfileDataType.PHONENUMBER:
      return i18n.t('dataType.phoneNumber');
    case ProfileDataType.BIRTHDATE:
      return i18n.t('dataType.birthDate');
    case ProfileDataType.BUSINESSNAME:
      return i18n.t('dataType.businessName');
    default:
      return i18n.t('dataType.customField');
  }
};

export const businessInvitePropertyTypeToString = (type: BusinessInvitePropertyType): string => {
  if (type in ProfileDataType) {
    return profileDataTypeToString(type as ProfileDataType);
  }
  return capitalizeFirstLetter(type);
};

export const customFieldTypeToString = (field: CustomField): string => {
  switch (field.type) {
    case CustomFieldType.COMMUNICATION_NAME:
      return i18n.t('dataType.communicationName');
    case CustomFieldType.TEXT:
      return field.label;
    case CustomFieldType.MULTIPLE_CHOICE:
      return field.label;
    default:
      return i18n.t('dataType.customField');
  }
};

/**
 * Checks if email is valid.
 * @param email email to check
 * @returns true if email is valid, false otherwise
 */
export function isValidEmail(email: string): boolean {
  /* eslint-disable no-useless-escape */
  return validator.validate(email);
}

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

// Function to capitalize each word in a string
export function capitalizeWords(str: string): string {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function getInitials(name: string): string {
  if (!name) return '';
  return name
    .split(' ')
    .map((part) => part.charAt(0))
    .join('');
}

export function businessTypeToString(type: BusinessType): string {
  switch (type) {
    case BusinessType.BUSINESS:
      return 'bedrijf';
    case BusinessType.WEDDING:
      return 'bruiloft';
    case BusinessType.BIRTH:
      return 'geboorte';
    case BusinessType.FUNERAL:
      return 'begrafenis';
    default:
      return 'eigen event';
  }
}

export function adminRoleToString(role: AdminRole): string {
  switch (role) {
    case AdminRole.OWNER:
      return i18n.t('admin.owner');
    case AdminRole.MEMBER:
      return i18n.t('admin.member');
    default:
      return '';
  }
}

export function getInitialsFromAlias(name: string): string {
  return name
    .split('+')
    .map((part) => part.charAt(0).split(' ').join(''))
    .join('');
}

export function joinWithAnd(strings: string[]): string {
  if (strings.length === 0) return '';
  if (strings.length === 1) return strings[0];
  return `${strings.slice(0, strings.length - 1).join(', ')} ${i18n.t('general.and')} ${
    strings[strings.length - 1]
  }`;
}

export function excelColumnTypeToString(type: ExcelColumnType): string {
  switch (type) {
    case ExcelColumnType.FULL_NAME:
      return i18n.t('general.fullName');
    case ExcelColumnType.FIRST_NAME:
      return i18n.t('general.firstName');
    case ExcelColumnType.INFIX:
      return i18n.t('general.infix');
    case ExcelColumnType.LAST_NAME:
      return i18n.t('general.lastName');
    case ExcelColumnType.EMAIL:
      return i18n.t('dataType.email');
    case ExcelColumnType.PHONENUMBER:
      return i18n.t('dataType.phoneNumber');
    case ExcelColumnType.STREET:
      return i18n.t('dataType.street');
    case ExcelColumnType.CITY:
      return i18n.t('dataType.city');
    case ExcelColumnType.POSTAL_CODE:
      return i18n.t('dataType.postalCode');
    case ExcelColumnType.COUNTRY:
      return i18n.t('dataType.country');
    case ExcelColumnType.BIRTHDATE:
      return `${i18n.t('dataType.birthDate')} (DD-MM-YYYY)`;
    case ExcelColumnType.COMMUNICATION_NAME:
      return i18n.t('dataType.communicationName');
    case ExcelColumnType.EVENT_PARTICIPATION:
      return i18n.t('dataType.eventParticipation');
    default:
      return type;
  }
}

export function translateEventParticipationAnswer(answer: EventParticipationAnswer): string {
  switch (answer) {
    case EventParticipationAnswer.YES:
      return i18n.t('page.eventDetails.answerTitle.yes');
    case EventParticipationAnswer.MAYBE:
      return i18n.t('page.eventDetails.answerTitle.maybe');
    case EventParticipationAnswer.NO:
      return i18n.t('page.eventDetails.answerTitle.no');
    case EventParticipationAnswer.PENDING:
      return i18n.t('page.eventDetails.answerTitle.pending');
    default:
      return '';
  }
}

export function formatDateAndTime(date: Date): string {
  const yearAddition = date.getFullYear() === new Date().getFullYear() ? '' : ' y';

  return format(date, `ccc d MMM${yearAddition}',' HH:mm'h'`, {
    locale: (store.getState() as RootState).application.language === 'nl' ? nl : undefined,
  });
}

export function formatDate(date: Date): string {
  const yearAddition = date.getFullYear() === new Date().getFullYear() ? '' : ' y';

  return format(date, `ccc d MMM${yearAddition}`, {
    locale: (store.getState() as RootState).application.language === 'nl' ? nl : undefined,
  });
}

export function formatDateForFileName(date: Date): string {
  return format(date, 'd-MM-y');
}
