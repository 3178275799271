import { CustomMultipleChoiceField } from '../services/model/inviteService.model';

export type Event = {
  id: number;
  businessId: number;
  title: string;
  memberCount: number;
  description: string;
  location: string;
  locationUrl?: string;
  startTime: Date;
  endTime?: Date;
  type: EventType;
  isCancelled: boolean;
  organizer: string;
  role: EventRole;
  participants: EventParticipant[];
  dateOptions: DateOption[];
  isRecurring: boolean;
  frequency: Frequency;
  isDatePicker: boolean;
  recurrenceBatchId: string;
  customFields: CustomEventMulipleChoiceField[];
};

export type CustomEventMulipleChoiceField = CustomMultipleChoiceField & {
  responses: EventCustomFieldResponse[];
};

export interface EventCustomFieldResponse {
  userId: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    picture: string;
  };
  response: string;
  customFieldId: number;
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface DateOption {
  id: number;
  startTime: Date;
  endTime?: Date;
  answers: DateOptionAnswer[];
}

export interface DateOptionAnswer {
  id: number;
  firstName: string;
  lastName: string;
  picture?: string;
  answer: EventParticipationAnswer;
  description?: string;
}

export enum EventParticipationAnswer {
  YES = 'YES',
  MAYBE = 'MAYBE',
  NO = 'NO',
  PENDING = 'PENDING',
}

export enum RecurrenceEditType {
  SINGLE = 'SINGLE',
  ALL = 'ALL',
  FUTURE = 'FUTURE',
}

export type EventParticipant = {
  id: number;
  firstName: string;
  lastName: string;
  picture: string;
  role?: EventRole;
  description: string;
  answer?: EventParticipationAnswer;
  answers?: DateOptionAnswer[];
};

export enum EventRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum EventType {
  SOCIAL = 'SOCIAL',
  WORK = 'WORK',
  SPORT = 'SPORT',
  OTHER = 'OTHER',
}

export type CreateEventModel = {
  title: string;
  description: string;
  location: string;
  locationUrl: string; // URL to the location in Google Maps, filled if location is retrieved from the Google Places Api
  startTime: Date;
  endTime?: Date;
  isRecurring?: boolean;
  frequency?: Frequency;
  recurrenceEndDate?: Date;
  type: EventType;
  isDatePicker: boolean;
  customFields: CustomMultipleChoiceField[];
  dateOptions: Omit<DateOption, 'id' | 'answers'>[];
};

export type UpdateEventModel = Omit<
  CreateEventModel,
  'isRecurring' | 'frequency' | 'recurrenceEndDate' | 'customFields' | 'isDatePicker'
>;

export enum EventViewType {
  REGULAR = 'REGULAR',
  DATE_PICKER = 'DATE_PICKER',
  PAST = 'PAST',
  ALL_REGULAR = 'ALL_REGULAR',
  ALL = 'ALL',
}
