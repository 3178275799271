import { FaMapMarkerAlt } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AddressPicker from '../../components/forms/AddressPicker';
import { Address, ProfileDataType } from '../../../types/Profile';
import PostcardModal from '../../components/modals/PostcardModal';
import CheckoutForm from '../../components/forms/CheckoutForm';
import orderService from '../../../services/orderService';
import ProfileModal from '../../components/modals/ProfileModal';
import { RootState } from '../../../redux/reducers';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { ConnectionIdentifier, CustomPostcardTemplate, Discount, PostcardTemplate } from '../../../types/misc';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import {
  filterB2CIds,
  filterLocalContactIds,
  filterPersonalMomentConnectionIds,
} from '../../../utils/connectionUtils';
import useProfileFields from '../../../hooks/account/useProfileFields';
import Button from '../../components/buttons/Button';
import DiscountInput from '../../components/misc/DiscountInput';
import useNavigateAfterBusinessChange from '../../../hooks/effects/useNavigateAfterBusinessChange';
import { UploadData } from '../../components/modals/uploadPostcardModal';
import { getSelectedBusiness } from '../../../hooks/business/useSelectedBusiness';

const STRIPE_KEY: string =
  process.env.NODE_ENV === 'development'
    ? 'pk_test_51L3FClIa3xVgOWHI4HnJjRmYWMe6aLTLQMc3TlK7iEu4qvQwDTCF52UIeSIYkbTJjn50ZBM0CVJomsKLfXFWYYXw00fFxrK58D'
    : 'pk_live_51L3FClIa3xVgOWHIvrqdlBQcO3b07IRorN4RNYAOnNm1dfhEurexzXueTAIhdvuxZi1Sb5HElmXxU13cZMQU941M00riqzSlib';

const stripePromise = loadStripe(STRIPE_KEY);

export default function PostcardPage(): JSX.Element {
  useNavigateAfterBusinessChange();
  const businessId = getSelectedBusiness()?.businessId;
  const location = useLocation();
  const { t } = useTranslation();
  const connectionsTotal = useSelector((state: RootState) => state.connections).length;

  const [connections, setConnections] = useState<ConnectionIdentifier[]>(
    location?.state?.connections.map(({ id, type }: Connection) => ({ id, type })) || [],
  );
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [postcardText, setPostCardText] = useState<string>('');
  const [postcardfileName, setPostcardfileName] = useState<string>('');
  const [uploadCustomData, setUploadCustomData] = useState<UploadData>({
    imgUrl: '',
    imgFile: null,
  });
  const [postcardTemplates, setPostcardTemplates] = useState<PostcardTemplate[]>([]);
  const [postcardTemplate, setPostcardTemplate] = useState<PostcardTemplate | null>(null);
  const [showPostcardModal, setShowPostcardModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showChangeSelectionModal, setShowChangeSelectionModal] = useState<boolean>(false);
  const [disabledForm, setDisabledForm] = useState<boolean>(true);
  const [discount, setDiscount] = useState<Discount | undefined>(undefined);

  const addresses = useProfileFields().filter(
    (field) => field.dataType === ProfileDataType.ADDRESS,
  );
  const PRICE_PER_POSTCARD = postcardTemplates?.length
    ? +(postcardTemplates[0].labels.find((l) => l.startsWith('price'))?.split(':')[1] || 0)
    : 0;

  async function getCustomPostCard(id: number): Promise<PostcardTemplate[]> {
    return orderService.getPostcardImagesByBusinessId(id).then((res) => {
      return res.map((customPostcard) => {
        const template: PostcardTemplate = {
          id: CustomPostcardTemplate.ID,
          name: 'Custom',
          thumbnail: customPostcard.thumbnail,
          thumbnailId: customPostcard.id.toString(),
          labels: ['price:349', 'custom'],
          mergeVariables: ['message'],
          format: 'POSTCARD_SQ15',
        };
        return template;
      });
    });
  }

  useEffect((): void => {
    if (!clientSecret) orderService.createPaymentIntent(399).then(setClientSecret);
    orderService.getPostcardTemplates().then(async (templates) => {
      if (businessId) {
        const customPostcards = await getCustomPostCard(businessId);
        setPostcardTemplates([...customPostcards, ...templates]);
      }
    });
  }, []);

  useEffect((): void => {
    if (!selectedAddress || postcardText === '' || !postcardTemplate || !connections.length)
      setDisabledForm(true);
    else setDisabledForm(false);
  }, [selectedAddress, postcardText, postcardTemplate, connections.length]);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (event.target.value.length > 256) return;
    setPostCardText(event.target.value);
  };

  const options = {
    appearance: {
      theme: 'stripe',
    },
    clientSecret,
  } as StripeElementsOptions;

  const paymentOverview = (
    <>
      <h2 className="mb-2 text-3xl font-serif md:text-3xl font-semibold md:font-bold">
        {t('page.payment.overview')}
      </h2>
      <div className="">
        <p>{t('page.payment.postcard.totalCost')}</p>
        <div className="border-y border-primary py-4 my-4 text-gray-500 font-medium">
          <div className="justify-between flex">
            <p>
              {connections.length}{' '}
              {t('page.payment.postcard.card', {
                count: connections.length,
              })}
            </p>
            <p>€{(PRICE_PER_POSTCARD * connections.length) / 100 || '--.--'}</p>
          </div>
          <div className="justify-between flex text-primary pt-2">
            <p>{t('page.payment.discount')}</p>
            <p>
              €
              {((PRICE_PER_POSTCARD * connections.length * (discount?.amount ?? 0)) / 100).toFixed(
                2,
              )}
            </p>
          </div>
          <DiscountInput className=" mt-4" discount={discount} setDiscount={setDiscount} />
        </div>
        <div className="justify-between flex font-medium text-lg">
          <p>{capitalizeFirstLetter(t('general.total'))}</p>
          <p>
            €
            {(
              (PRICE_PER_POSTCARD * connections.length * (1 - (discount?.amount ?? 0))) /
              100
            ).toFixed(2) || '--.--'}
          </p>
        </div>
      </div>
    </>
  );

  const onUploadModalClose = async (data: UploadData) => {
    const customTemplate: PostcardTemplate = {
      id: CustomPostcardTemplate.ID,
      name: 'Custom',
      thumbnail: data.imgUrl,
      labels: ['price:349', 'custom'],
      mergeVariables: ['message'],
      format: 'POSTCARD_SQ15',
    };

    setUploadCustomData(data);
    orderService.getPostcardTemplates().then(async (templates) => {
      if (businessId) {
        const customPostcards = await getCustomPostCard(businessId);
        setPostcardTemplates([customTemplate, ...customPostcards, ...templates]);
      }
    });
  };

  const onPostCardModalClose = async (isClose: boolean) => {
    if (!uploadCustomData.imgFile || !businessId) {
      setShowPostcardModal(!isClose);
      return;
    }
    const res = await orderService.uploadCustomPostcard(uploadCustomData.imgFile, businessId);
    if (res && res.id) {
      setPostcardfileName(res.fileName);
      setUploadCustomData({ imgUrl: '', imgFile: null }); // reset the upload data
      setShowPostcardModal(!isClose);
      orderService.getPostcardTemplates().then(async (templates) => {
        if (businessId) {
          const customPostcards = await getCustomPostCard(businessId);
          setPostcardTemplates([...customPostcards, ...templates]);
        }
      });
    } else {
      toast.error(t('toast.error.uploadPostcard'));
    }
  };

  const handleShowPostcardModal = (): void => {
    setShowPostcardModal(true);
    setPostcardTemplate(null);
  }

  return (
    <>
      <div className=" mx-auto grid grid-cols-1 md:grid-cols-8  gap-6">
        <div className="grid grid-cols-1 gap-6 md:col-span-5">
          {/* Information */}
          <div data-testid="postcard-info" className="bg-secondary-200 rounded-2xl p-4 h-fit">
            <h2 className="mb-2 pl-2 font-serif text-3xl font-semibold">
              {t('page.payment.postcard.title')}
            </h2>
            <div className="pl-2">
              <p>{t('page.payment.postcard.subtitle')}</p>
              <p>
                {t('page.payment.postcard.pricePerCard', {
                  price: (PRICE_PER_POSTCARD / 100).toFixed(2) || '--.--',
                })}
              </p>
            </div>
          </div>

          {/* Payment selection */}
          <div data-testid="postcard-selection" className="bg-secondary-50 rounded-2xl h-fit">
            <div className="flex justify-between mb-2 pl-2 ">
              <span className="flex">
                <p className="text-primary font-semibold">{connections.length}</p>/
                {connectionsTotal}
                {t('page.payment.selected')}
              </span>
              <p
                data-testid="edit-selection"
                onClick={(): void => setShowChangeSelectionModal(true)}
                className="underline cursor-pointer">
                {t('page.payment.editSelection')}
              </p>
            </div>

            {/* Choose card */}
            <div className="border-y border-secondary-200 py-6 my-6 mb-2 pl-2 flex flex-col">
              <h2 className="text-2xl font-semibold mb-2">{t('page.payment.postcard.design')}</h2>
              <p className="mb-2">{t('page.payment.postcard.designQuestion')}</p>
              {!postcardTemplate ? (
                <div
                  onClick={handleShowPostcardModal}
                  className="w-40 h-40 border-dashed border-primary-900 cursor-pointer hover:border-opacity-80 border-2 rounded-lg flex items-center group">
                  <Button
                    variant="primary"
                    onClick={handleShowPostcardModal}
                    className="mx-auto group-hover:bg-opacity-80">
                    {t('page.payment.postcard.chooseCard')}
                  </Button>
                </div>
              ) : (
                <div
                  data-testid="postcard-template"
                  className="w-40 mr-4 h-40 relative border-primary-900 cursor-pointer   border-2 rounded-lg flex items-center group"
                  onClick={handleShowPostcardModal}>
                  <img
                    data-testid="card-small"
                    src={postcardTemplate.thumbnail}
                    alt={postcardTemplate.name}
                    className="w-full h-full rounded-lg"
                  />
                  <div className="absolute w-full h-full rounded-md opacity-0 font-medium text-secondary-200 hover:bg-primary-900 hover:opacity-50 text-center flex items-center justify-center">
                    {t('page.payment.postcard.switchCard')}
                  </div>
                </div>
              )}
            </div>

            {/* Get Text */}
            <div className="border-b pb-6 border-gray-400 my-6 mb-2 pl-2 flex flex-col">
              <h2 className="text-2xl font-semibold mb-2">
                {t('page.payment.postcard.yourMessage')}
              </h2>
              <p className="mb-2">{t('page.payment.postcard.yourMessageSubtitle')}</p>
              <p className="text-gray-600 text-sm pt-2 pb-1">{t('page.payment.postcard.text')}</p>
              <div className="relative">
                <textarea
                  data-testid="postcard-text"
                  className="rounded-lg h-32 resize-none w-full placeholder-secondary-200"
                  placeholder={t('page.payment.postcard.placeholder')}
                  onChange={handleTextChange}
                  value={postcardText}
                />
                <div className="absolute bottom-2 right-2 text-primary-900 text-sm">
                  {postcardText.length}/{256}
                </div>
              </div>
            </div>

            <div data-testid="postcard-address" className="py-6">
              <div className="flex items-center">
                <FaMapMarkerAlt className="h-6 w-6 text-primary-900 mr-1 " />
                <h2 className="text-2xl font-semibold">{t('page.payment.yourAddress')}</h2>
              </div>
              {addresses.length > 0 ? (
                <p>
                  {t('page.payment.postcard.addressSend')}
                  <span
                    className="underline cursor-pointer hover:font-semibold"
                    onClick={() => setShowProfileModal(true)}>
                    {t('page.payment.add')}
                  </span>
                  .
                </p>
              ) : (
                <p>
                  <span
                    className="underline cursor-pointer hover:font-semibold"
                    onClick={() => setShowProfileModal(true)}>
                    {t('page.payment.addAddress')}
                  </span>
                </p>
              )}
              <ProfileModal
                open={showProfileModal}
                setOpen={setShowProfileModal}
                type={ProfileDataType.ADDRESS}
                modalType="CREATE"
              />
              <AddressPicker
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
              />
            </div>

            <div className="border-t md:hidden pt-6 border-secondary-200 mt-6 mb-2 pl-2 flex flex-col">
              {paymentOverview}
            </div>

            <div data-testid="postcard-payment" className="py-6">
              <h2 className="text-2xl font-semibold border-t border-secondary-200 pt-6">
                {t('page.payment.paymentMethod')}
              </h2>
              <p>{t('page.payment.paymentMethodQuestion')}</p>
              {clientSecret && stripePromise && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    disabled={disabledForm}
                    setDisabled={setDisabledForm}
                    onSubmit={(): Promise<void> =>
                      orderService.placePostcardOrder(
                        filterB2CIds(connections),
                        filterLocalContactIds(connections),
                        filterPersonalMomentConnectionIds(connections),
                        postcardText,
                        selectedAddress!,
                        postcardTemplate?.id.split('.')[0]!,
                        clientSecret,
                        discount?.code || '',
                        postcardfileName,
                      )
                    }
                  />
                </Elements>
              )}
            </div>
          </div>
        </div>

        {/* Overview of the costs */}
        <div
          data-testid="postcard-overview"
          className="bg-secondary-200 hidden md:flex md:flex-col rounded-2xl p-4 px-6 md:col-span-3 h-fit sticky top-[105px] bottom-[500px]">
          {paymentOverview}
        </div>
      </div>
      <PostcardModal
        card={postcardTemplate}
        templates={postcardTemplates}
        setCard={setPostcardTemplate}
        showModal={showPostcardModal}
        onUploadClose={onUploadModalClose}
        onClose={onPostCardModalClose}
        setShowModal={setShowPostcardModal}
        setTemplates={setPostcardTemplates}
      />
      <ChangeSelectionModal
        open={showChangeSelectionModal}
        setOpen={setShowChangeSelectionModal}
        selection={connections}
        setSelection={setConnections}
        type="address"
      />
    </>
  );
}
